<template>
  <div justify="center">
    <v-icon
      small
      @click="openForm"
    >
      {{ icons.mdiEye }}
    </v-icon>

    <v-dialog
      v-model="dialog"
      width="65%"
      persistent
    >
      <v-card tile>
        <overlays
          :is-absolute="true"
          :is-show="absoluteOverlayStatus"
        ></overlays>
        <v-card>
          <v-card-title>注文詳細</v-card-title>
          <v-card-subtitle class="px-6" style="font-size:1rem;">
            <v-row>
              <v-col cols="4">
                <div>
                  <span style="font-weight:bold;">注文ID: </span>
                  <span>{{ orderProgressList.order_id }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">注文者: </span>
                  <span>{{ orderProgressList.mbr_name }} ({{ orderProgressList.mbr_id }})</span>
                </div>
                <div>
                  <span style="font-weight:bold;">注文番号: </span>
                  <span>{{ orderProgressList.order_no }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">注文日時: </span>
                  <span>{{ orderProgressList.order_dt }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">決済金額: </span>
                  <span :id="OrderTotalColor">{{ formatData(orderProgressList.order_total) }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">注文状態: </span>
                  <span>{{ status }}</span>
                </div>
              </v-col>
              <v-col cols="8">
                <div>
                  <span style="font-weight:bold;">お届け先: </span>
                  <span>{{ orderProgressList.addr_name }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">お届け先電話番号: </span>
                  <span>{{ orderProgressList.addr_telno }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">郵便番号: </span>
                  <span>{{ orderProgressList.addr_zip }}</span>
                </div>
                <div>
                  <span style="font-weight:bold;">お届け先住所: </span>
                  <span>{{ orderProgressList.addr_addr1 }} {{ orderProgressList.addr_addr2 }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card-subtitle>

          <!-- <v-divider></v-divider> -->

          <v-card-text>
            <v-data-table
              :headers="detailHeaders"
              :items="orderProgressList.details"
              :items-per-page="-1"
              hide-default-footer
              style="border:thin solid rgba(0, 0, 0, 0.14)"
              class="elevation-0"
            >
              <template v-slot:[`item.order_qty`]="{ item }">
                {{ formatData(item.order_qty) }}
              </template>
              <template v-slot:[`item.order_tip`]="{ item }">
                {{ formatData(item.order_tip) }}
              </template>
              <template v-slot:[`item.itemTotal`]="{ item }">
                {{ formatData(item.order_tip * item.order_qty) }}
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <td
                    class="font-weight-bold"
                    :colspan="5"
                    style="background: #fafafa"
                  >
                    <label>小計</label>
                  </td>
                  <td
                    class="font-weight-bold"
                    style="text-align: right; background: #fafafa"
                  >
                    <label>{{ totalPrice }}</label>
                  </td>
                </tr>
                <tr>
                  <td
                    :colspan="5"
                  >
                    <label>送料</label>
                  </td>
                  <td
                    style="text-align: right;"
                  >
                    <label>{{ formatData(orderProgressList.shipping_fee) }}</label>
                  </td>
                </tr>
                <tr v-if="couponShow">
                  <td
                    :colspan="5"
                  >
                    <label>クーポン</label>
                  </td>
                  <td
                    style="text-align: right;"
                  >
                    <label id="couponPriceColor">-{{ formatData(orderProgressList.coupon_discount) }}</label>
                  </td>
                </tr>
                <tr>
                  <td
                    class="font-weight-bold"
                    :colspan="5"
                    style="background: #fafafa"
                  >
                    <label>合計</label>
                  </td>
                  <td
                    class="font-weight-bold"
                    style="text-align: right; background: #fafafa"
                  >
                    <label>{{ sumPrice }}</label>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions align="center" class="d-flex justify-center mt-3">
            <v-btn
              color="secondary"
              outlined
              elevation="0"
              @click="closeForm()"
            >
              <span>戻る</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import {
  mdiEye,
  mdiClose,
} from '@mdi/js'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  props: ['id', 'status'],
  data: () => ({
    OrderTotalColor: 'black',
    icons: {
      mdiEye,
      mdiClose,
    },
    detailHeaders: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        width: '7%',
        value: 'detail_id',
      },
      {
        text: '商品コード',
        align: 'left',
        sortable: false,
        width: '20%',
        value: 'product_code',
      },
      {
        text: '商品名',
        align: 'left',
        sortable: false,
        width: '28%',
        value: 'product_name',
      },
      {
        text: '注文数量',
        align: 'right',
        sortable: false,
        width: '15%',
        value: 'order_qty',
      },
      {
        text: '単価',
        align: 'right',
        sortable: false,
        width: '15%',
        value: 'order_tip',
      },
      {
        text: '金額',
        align: 'right',
        sortable: false,
        width: '15%',
        value: 'itemTotal',
      },
    ],
    tableList: [],
    couponShow: false,
    dialog: false,
  }),
  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('orderStore', ['orderProgressList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    sumPrice() {
      let sum = 0
      if (this.orderProgressList.details) {
        this.orderProgressList.details.forEach(a => {
          sum += a.order_tip * a.order_qty
        })
        sum = sum - this.orderProgressList.coupon_discount + this.orderProgressList.shipping_fee
      }

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
    totalPrice() {
      let sum = 0
      if (this.orderProgressList.details) {
        this.orderProgressList.details.forEach(a => {
          sum += a.order_tip * a.order_qty
        })
      }

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
  },
  watch: {
  },
  methods: {
    ...mapMutations('app', ['setAbsoluteOverlayStatus']),
    ...mapActions('orderStore', ['loadOrderProgress']),
    openForm() {
      this.dialog = true

      // console.log(this.id)
      this.loadData()
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },
    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadOrderProgress(this.id).then(() => {
        // console.log('adasda', this.orderProgressList)
        if (this.orderProgressList.coupon_discount !== 0 && this.orderProgressList.coupon_discount !== null) {
          this.couponShow = true
        }

        this.redColor()
        this.setAbsoluteOverlayStatus(false)
      })
    },

    redColor() {
      if (this.orderProgressList.order_total !== '' && this.orderProgressList.order_total !== null) {
        if (this.orderProgressList.order_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') !== this.sumPrice) {
          this.OrderTotalColor = 'OrderTotalColor'
        }
      }
    },
  },
}
</script>

<style scoped>
.theme--light.v-data-table >>> th {
  border-top: 0;
}
.order_proc_memo{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 20rem;
}
.os_desc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 10rem;
}
#OrderTotalColor {
  color: red !important;
}
#couponPriceColor {
  color: red !important;
}
</style>
