<template>
  <div id="order-by-member-list">
    <v-card>
      <v-card-title>注文実績（一覧）</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="orderStatus"
            :items="orderStatusMaster"
            placeholder="注文状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenuPud"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDatePud"
                placeholder="決済(予定)日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDatePud" @input="dateStartMenuPud = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="注文日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="注文日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" @input="dateEndMenu = false">
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field v-model="search" placeholder="Search" outlined hide-details dense class="user-search me-3 mb-4">
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" :to="{ name: 'order-create', params: { query: this.$route.query } }">
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn :loading="exportStatus" color="secondary" outlined class="mb-4 me-3" @click="downloadCSV">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table :headers="headers" :items="orderByMemberList" sort-by class="elevation-1" :search="search">
        <template v-slot:[`item.ord_id`]="{ item }">
          <a href="javascript:void(0);" @click="pushOrderHistory(item)">{{ item.ord_id }}</a>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="
                  item.mbr_status === 9
                    ? 'text-decoration: line-through red; color:#696969'
                    : 'text-decoration: underline; color:#696969'
                "
                v-on="on"
              >{{ item.mbr_sei }} {{ item.mbr_mei }}</a>
            </template>
            <span>{{ item.mbr_sei }} {{ item.mbr_mei }}</span><br />
            <span>{{ item.mbr_sei_kana }} {{ item.mbr_mei_kana }}</span><br />
            <span>{{ item.mbr_zip }}</span><br />
            <span>{{ item.mbr_address1 }}{{ item.mbr_address2 }}</span><br />
            <span>{{ item.mbr_email }}</span><br />
            <span>{{ item.mbr_mobile }}</span><br />
          </v-tooltip>
        </template>
        <template v-slot:[`item.order_dt`]="{ item }">
          <div>
            <span>{{ item.order_dt }}</span>
          </div>
        </template>
        <template v-slot:[`item.os_desc`]="{ item }">
          <div :style="item.os_code > 7 ? 'color:red' : ''">
            {{ item.os_desc }}
          </div>
        </template>
        <template v-slot:[`item.order_discount`]="{ item }">
          <label id="couponPriceColor">{{
            item.order_discount ? '-' + item.order_discount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''
          }}</label>
        </template>
        <template v-slot:[`item.order_amount`]="{ item }">
          <label>{{
            item.order_amount ? item.order_amount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''
          }}</label>
        </template>
        <template v-slot:[`item.shipping_fee`]="{ item }">
          <label>{{
            item.shipping_fee ? item.shipping_fee.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''
          }}</label>
        </template>
        <template v-slot:[`item.order_total`]="{ item }">
          <label :style="item.os_code === 99 ? 'text-decoration: line-through' : ''">{{
            item.order_total ? item.order_total.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ''
          }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <OrderDialog :id="item.ord_id" class="py-2 pl-1" :status="item.os_desc" @refresh="loadData">
            </OrderDialog>
            <div class="py-2 pl-1">
              <v-icon small :disabled="item.os_code > 2 && item.os_code < 90" @click="editItem(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
        <!-- <template v-slot:[`item.order_code`]="{ item }">
          <v-tooltip v-if="item.order_code" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.order_code }}</span>
            </template>
            <span>{{ item.type }}<br />{{ item.product_name }}<br />{{ item.price }}</span>
          </v-tooltip>
        </template> -->
        <template v-slot:[`body.append`]>
          <tr>
            <td class="font-weight-bold" style="background: #fafafa" :colspan="2">
              <label>合計価格(円)</label>
            </td>
            <td class="font-weight-bold" style="text-align: right; background: #fafafa">
              <label>{{ orderTotalPrice }}</label>
            </td>
            <td style="background: #fafafa" :colspan="2"></td>
            <td class="font-weight-bold" style="text-align: right; background: #fafafa">
              <label>{{ payTotalPrice }}</label>
            </td>
            <td style="background: #fafafa" :colspan="4"></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar, mdiPencil, mdiPlus, mdiExportVariant,
} from '@mdi/js'
import OrderDialog from '@/views/bimi/order/OrderByMemberList/OrderDialog.vue'

// import DeleteForm from '@/views/bimi/product/item/ProductsDelete.vue'

export default {
  components: {
    // DeleteForm,
    OrderDialog,
  },
  data: () => ({
    exportStatus: false,
    startDatePud: '',
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    dateStartMenuPud: '',
    mbr_sei_kana: '',
    mbr_mei_kana: '',
    orderStatus: '',
    search: '',
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiPlus,
      mdiExportVariant,
    },
    headers: [
      {
        text: '注文ID',
        value: 'ord_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '12%',
        fixed: true,
      },
      {
        text: '売上金額',
        value: 'order_amount',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '送料',
        value: 'shipping_fee',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '割引',
        value: 'order_discount',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '決済金額',
        value: 'order_total',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文日時',
        value: 'order_dt',
        width: '11%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '決済予定日',
        value: 'order_pud',
        align: 'left',
        width: '12%',
        fixed: true,
      },
      {
        text: '状態',
        value: 'os_desc',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '6%',
        align: 'right',
        fixed: true,
      },
      {
        text: 'メールアドレス',
        value: 'mbr_email',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
      {
        text: '電話番号',
        value: 'mbr_mobile',
        align: ' d-none',
      },
      {
        text: '注文番号',
        value: 'order_no',
        align: ' d-none',
      },
    ],
  }),
  computed: {
    ...mapState('orderStore', ['orderByMemberList', 'orderStatusMaster', 'OrdersDetail']),
    orderTotalPrice() {
      let totalPrice = 0
      this.orderByMemberList.forEach(obm => {
        if (obm.order_amount) {
          totalPrice += obm.order_amount
        }
      })

      return totalPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
    payTotalPrice() {
      let totalPrice = 0
      this.orderByMemberList.forEach(obm => {
        if (obm.os_code !== 99 && obm.order_total) {
          totalPrice += obm.order_total
        }
      })

      return totalPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
  },
  watch: {
    orderStatus(newValue) {
      this.changeRouteQuery('os_code', newValue)
      this.loadData()
    },
    startDatePud(newValue) {
      this.changeRouteQuery('start_date_pud', newValue)
      this.loadData()
    },
    startDate(newValue) {
      this.changeRouteQuery('start_date', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('end_date', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearOrderMember()
    this.clearOrderStatusMaster()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('orderStore', ['clearOrderStatusMaster', 'clearOrderMember']),
    ...mapActions('orderStore', ['loadOrderStatus', 'loadOrderMember', 'loadOrdersDetail']),
    changeSeiKana() {
      this.changeRouteQuery('mbr_sei_kana', this.mbr_sei_kana)
      this.loadData()
    },
    changeMeiKana() {
      this.changeRouteQuery('mbr_mei_kana', this.mbr_mei_kana)
      this.loadData()
    },
    pushOrderHistory(item) {
      this.$router.push({
        name: 'order-history',
        params: {
          id: item.ord_id,
        },
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([this.loadOrderStatus(), this.loadOrderMember(this.$route.query)])
        .catch(error => {
          console.log('loadData', error)
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'order-edit',
        params: {
          id: item.ord_id,
          query: this.$route.query,
        },
      })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path,
        query,
      })
    },

    loadQuery() {
      this.mbr_mei_kana = this.$route.query.mbr_mei_kana ?? ''
      this.mbr_sei_kana = this.$route.query.mbr_sei_kana ?? ''
      // eslint-disable-next-line radix
      this.orderStatus = this.$route.query.os_code ? parseInt(this.$route.query.os_code) : ''
      this.startDate = this.$route.query.start_date ?? ''
      this.startDatePud = this.$route.query.start_date_pud ?? ''
      this.endDate = this.$route.query.end_date ?? ''
    },
    downloadCSV() {
      this.exportStatus = true
      this.setOverlayStatus(true)
      this.loadOrdersDetail({
        end_date: this.$route.query.end_date,
        start_date: this.$route.query.start_date,
        start_date_pud: this.$route.query.start_date_pud,
        os_code: this.$route.query.os_code,
      }).then(() => {
        console.log('orderdetail', this.OrdersDetail)
        // eslint-disable-next-line no-useless-concat
        let csv = '\ufeff' + '注文ID,会員ID,会員氏名,会員電話番号,注文者の郵便番号,注文者の住所,注文番号,注文日時,決済予定日,注文詳細ID,商品コード,商品名,注文数量,単価,売上金額,送料,割引,クーポンコード,経済金額,注文状態,お届け先,お届け先電話番号,郵便番号,お届け先住所\n'
        this.OrdersDetail.forEach(el => {
          const orderId = el.order_id ?? ''
          const mbrId = el.mbr_id ?? ''
          const mbrName = el.mbr_name ?? ''
          const mbrMoblie = el.mbr_mobile ?? ''
          const mbrZip = el.mbr_zip ?? ''
          const mbrAddr = el.mbr_addr ?? ''
          const orderNo = el.order_no ?? ''
          const orderDt = el.order_dt ?? ''
          const orderPud = el.order_pud ?? ''
          const detailId = el.detail_id ?? ''
          const productCode = el.product_code ?? ''
          const productName = el.product_name ?? ''
          const orderQty = el.order_qty ? el.order_qty.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
          const orderTip = el.order_tip ? el.order_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
          const orderAmount = el.order_amount ? el.order_amount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
          const shippingFee = el.shipping_fee ? el.shipping_fee.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
          const couponDiscount = el.coupon_discount ? el.coupon_discount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
          const couponCodes = el.coupon_codes ? el.coupon_codes.replace(/["\\[\]]/g, '') : ''
          const orderTotal = el.order_total ? el.order_total.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
          const osDesc = el.os_desc ?? ''
          const addrName = el.addr_name ?? ''
          const addrTelno = el.addr_telno ?? ''
          const addrZip = el.addr_zip ?? ''
          const addrAddr = el.addr_addr ?? ''

          const line = `"${orderId}","${mbrId}","${mbrName}","${mbrMoblie}","${mbrZip}","${mbrAddr}","${orderNo}","${orderDt}","${orderPud}","${detailId}","${productCode}","${productName}","${orderQty}","${orderTip}","${orderAmount}","${shippingFee}","${couponDiscount}","${couponCodes}","${orderTotal}","${osDesc}","${addrName}","${addrTelno}","${addrZip}","${addrAddr}"\n`
          csv += line
        })

        const blob = new Blob([csv], {
          type: 'text/csv',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)

        const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
        const newstr = nowstr.replace(/(-|:|T)/g, '')

        link.download = '注文実績一覧'.concat('-', newstr, '.csv')
        link.click()

        this.setOverlayStatus(false)
        this.exportStatus = false
      }).catch(error => {
        console.log('error', error)
        this.setOverlayStatus(false)
        this.exportStatus = false
      })
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
#couponPriceColor {
  color: red !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
